import { useCallback, useEffect, useState, useRef } from 'react';
const defaultSrc = { src: '', src2x: '' };
export const makeObjectFromSrc = image =>
  typeof image === 'string'
    ? {
        src: image,
        original: image,
        current: getOriginal({ src: image }),
        src2x: null,
      }
    : {
        ...image,
        current: image ? getOriginal(image) : getOriginal(defaultSrc),
      };

const getOriginal = ({ src, src2x }) =>
  window ? (window.devicePixelRatio > 1 && src2x ? src2x : src) : src;

const initialState = {
  src: null,
  original: null,
  src2x: null,
  current: null,
};

const useMedia = data => {
  // media = { src, original, src2x, current, isLoaded }
  const [media, setMedia] = useState(initialState);

  useEffect(() => {
    setMedia(makeObjectFromSrc(data));
  }, [data]);

  return media;
};

export const useVideo = data => {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { src, current } = useMedia(data);

  const load = useCallback(() => {
    videoRef.current = document.createElement('video');
    videoRef.current.src = src;
    videoRef.current.onloadeddata = () => {
      setIsLoaded(true);
    };
  }, [src]);

  return {
    isLoaded,
    src,
    current,
    load,
  };
};

export const useImage = data => {
  const [isLoaded, setIsLoaded] = useState(false);

  const { src, original, src2x, current } = useMedia(data);

  const load = useCallback(() => {
    setIsLoaded(true);
  }, []);

  return {
    isLoaded,
    src,
    original,
    src2x,
    current,
    load,
  };
};
