import { useRef, useEffect, useState } from 'react';

import * as constants from 'utils/constants';

export default function useLazyLoad(loadMedia) {
  const isReachedElement = useRef(false);

  const [position, setPosition] = useState(null);

  const ref = async node => {
    if (node !== null) {
      const nodePosition = node.getBoundingClientRect().top;

      setPosition(nodePosition);

      const currentPosition =
        document.documentElement.clientHeight +
        window.pageYOffset +
        constants.defaultLoadingHeight -
        nodePosition;

      if (currentPosition > 0) {
        await loadMedia();
      }
    }
  };

  useEffect(() => {
    function onScroll() {
      if (position !== null && !isReachedElement.current) {
        const elementPosition =
          document.documentElement.clientHeight + window.pageYOffset - position;
        if (elementPosition > 100) {
          isReachedElement.current = true;
          loadMedia();
        }
      }
    }
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [loadMedia, position]);

  return { ref };
}
